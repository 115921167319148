import { useCallback, useMemo, useState } from 'react';

import {
  AccordionGroup,
  BLOTTER_TABLE_FILTERS_CONTAINER_ID,
  Box,
  LookbackOption,
  MixpanelEvent,
  Tab,
  TabList,
  TabPanels,
  TabSize,
  Tabs,
  columnToColumnState,
  tabLabelerEnumerated,
  useDynamicCallback,
  useMixpanel,
  usePersistedTabs,
  usePortal,
  useTabs,
  type Column,
  type Order,
  type TabProps,
} from '@talos/kyoko';
import { ORDERS_BLOTTER_PREFIX } from 'containers/Blotters/Orders/tokens';
import { FilteredOrders } from './FilteredOrders';
import { OpenOrders } from './OpenOrders';
import type { BlotterTableOrderFilter } from './useOrderFilter';

export interface OrderTabProps extends TabProps {
  defaultFilter: Partial<BlotterTableOrderFilter>;
  defaultColumns?: (keyof Order | Partial<Column>)[];
}

const OPEN_ORDERS_TAB_ID = 'open-orders';
const RECENT_ORDERS_TAB_ID = 'recent-orders';

// Not excluding the recent orders tab here as it supports filtering
const TABS_EXCLUDED_IDS = [OPEN_ORDERS_TAB_ID];

const tabLabeler = tabLabelerEnumerated('Orders');

export function Orders() {
  const mixpanel = useMixpanel();
  const [initialFiltersOpen, setInitialFiltersOpen] = useState(false);

  const handleSelect = useDynamicCallback(() => setInitialFiltersOpen(false));
  const handleAdd = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.AddTab);
    setInitialFiltersOpen(true);
  });

  const openOrdersTab = useMemo(() => {
    return {
      label: 'Open',
      id: OPEN_ORDERS_TAB_ID,
      closable: false,
      editable: false,
      defaultFilter: {},
      defaultColumns: [],
    };
  }, []);

  const recentOrdersTab = useMemo(() => {
    return {
      label: 'Recent',
      id: RECENT_ORDERS_TAB_ID,
      closable: true,
      editable: true,
      defaultFilter: {
        _start: LookbackOption.Past24Hours,
      },
      defaultColumns: [],
    };
  }, []);

  const defaultPrincipalOrderTabs = [openOrdersTab, recentOrdersTab];

  const newTabDefaults = useMemo(() => {
    return {
      defaultFilter: {
        _start: LookbackOption.Past24Hours,
      },
      defaultColumns: [],
    };
  }, []);

  const persistedTabs = usePersistedTabs<OrderTabProps>(ORDERS_BLOTTER_PREFIX, {
    defaultInitialItems: defaultPrincipalOrderTabs,
    defaultInitialSelectedIndex: 0,
    excludedIDs: TABS_EXCLUDED_IDS,
    onSelect: handleSelect,
  });

  const tabs = useTabs<OrderTabProps>({
    ...persistedTabs,
    showAddTab: true,
    tabLabeler,
    onAdd: handleAdd,
  });

  const handleCloneTab = useCallback(
    (filter: BlotterTableOrderFilter, columns: Column[]) => {
      tabs.clone(tabs.selectedIndex, {
        defaultColumns: columns.map(columnToColumnState),
        defaultFilter: filter,
      });
    },
    [tabs]
  );

  const { setPortalRef: filtersContainerRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);
  return (
    <Tabs {...tabs} h="100%" size={TabSize.Large}>
      <TabList isBordered rightItems={<Box ref={filtersContainerRef} />}>
        {tabs.items.map((tab, idx) => (
          <Tab key={idx} {...tab} />
        ))}
      </TabList>
      <TabPanels style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
        {tabs.items.map(tab => (
          <AccordionGroup key={tab.id}>
            {tab.id === OPEN_ORDERS_TAB_ID ? (
              <OpenOrders
                key={tab.id}
                defaultFilter={(tab as OrderTabProps)?.defaultFilter ?? newTabDefaults.defaultFilter}
              />
            ) : (
              <FilteredOrders
                key={tab.id}
                blotterID={`${ORDERS_BLOTTER_PREFIX}/${tab.id}`}
                tabLabel={tab.label}
                // Checking default columns length is for backwards compatibility with old configs circa 2.36.0
                defaultColumns={
                  'defaultColumns' in tab && tab.defaultColumns?.length
                    ? tab.defaultColumns
                    : newTabDefaults.defaultColumns
                }
                defaultFilter={'defaultFilter' in tab ? tab.defaultFilter : newTabDefaults.defaultFilter}
                onCloneTab={handleCloneTab}
                initialIsOpen={initialFiltersOpen}
              />
            )}
          </AccordionGroup>
        ))}
      </TabPanels>
    </Tabs>
  );
}
export default Orders;
