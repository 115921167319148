import {
  ACTION,
  MixpanelEvent,
  MixpanelEventSource,
  MixpanelSourceProvider,
  type SidebarSubNavItem,
} from '@talos/kyoko';
import { Addresses } from 'containers/Portfolio/Addresses';
import { General } from 'containers/Settings/General';
import { SubAccountSettings } from 'containers/Settings/SubAccounts';
import { Trading } from 'containers/Settings/Trading';
import { TradingLimits } from 'containers/Settings/TradingLimits';
import { UserGroups } from 'containers/Settings/UserGroups';
import { useFeatureFlag, useRoleAuth } from 'hooks';
import { useIsPMSActive } from 'providers/PortfolioSettingContext';
import { useMemo } from 'react';
import { ApiKey } from './ApiKey';
import { Credentials } from './Credentials';
import { MultilegSecurityMaster } from './MultilegSecurityMaster';
import { SecurityMaster } from './SecurityMaster';
import { TradingAggregation } from './TradingAggregation';
import { TradingControls } from './TradingControls';
import { Users } from './Users';

export const SETTINGS_SUB_NAV_ITEMS: SidebarSubNavItem[] = [
  {
    label: 'General',
    path: '/settings/general',
    component: <General />,
  },
  {
    label: 'Trading',
    path: '/settings/trading',
    component: <Trading />,
  },
  {
    label: 'Trading Aggregations',
    path: '/settings/trading-aggregations',
    component: <TradingAggregation />,
  },
  {
    label: 'Trading Controls',
    path: '/settings/trading-controls',
    mixpanelEvent: MixpanelEvent.OpenTradingControlsSettings,
    id: 'trading-controls-nav-item',
    component: (
      <MixpanelSourceProvider value={MixpanelEventSource.TradingControls}>
        <TradingControls />
      </MixpanelSourceProvider>
    ),
  },
  {
    label: 'Sub Accounts',
    path: '/settings/sub-accounts',
    component: <SubAccountSettings />,
  },
  {
    label: 'User Groups',
    path: '/settings/user-groups',
    component: <UserGroups />,
  },
  {
    label: 'Users',
    path: '/settings/users',
    mixpanelEvent: MixpanelEvent.NavigateToUsers,
    component: (
      <MixpanelSourceProvider value={MixpanelEventSource.Users}>
        <Users />
      </MixpanelSourceProvider>
    ),
  },
  {
    label: 'Trading Limits',
    path: '/settings/trading-limits',
    component: <TradingLimits />,
  },
  {
    label: 'API Key',
    path: '/settings/api-key',
    component: <ApiKey />,
  },
  {
    label: 'Credentials',
    path: '/settings/credentials',
    mixpanelEvent: MixpanelEvent.OpenCredentials,
    component: (
      <MixpanelSourceProvider value={MixpanelEventSource.Credentials}>
        <Credentials />
      </MixpanelSourceProvider>
    ),
  },
  {
    label: 'Security Master',
    path: '/settings/security-master',
    component: (
      <MixpanelSourceProvider value={MixpanelEventSource.SecurityMaster}>
        <SecurityMaster />
      </MixpanelSourceProvider>
    ),
  },
  {
    label: 'Multileg Security Master',
    path: '/settings/multileg-security-master',
    component: (
      <MixpanelSourceProvider value={MixpanelEventSource.MultilegSecurityMaster}>
        <MultilegSecurityMaster />
      </MixpanelSourceProvider>
    ),
  },
  {
    label: 'Address Defaults',
    path: '/settings/address-defaults',
    component: (
      <MixpanelSourceProvider value={MixpanelEventSource.PortfolioAddresses}>
        <Addresses />
      </MixpanelSourceProvider>
    ),
    mixpanelEvent: MixpanelEvent.NavigateToPortfolioAddresses,
  },
];

export function useSettingsSubNavItems() {
  const { isAuthorized } = useRoleAuth();
  const { showMultiLegSecmaster, enableTradingControls } = useFeatureFlag();
  const isPMSActive = useIsPMSActive();

  return useMemo(
    () =>
      SETTINGS_SUB_NAV_ITEMS.filter(({ path }) => {
        if (path === '/settings/users') {
          return isAuthorized(ACTION.EDIT_FILTER_PERMISSIONS);
        }
        if (path === '/settings/trading-limits') {
          return isAuthorized(ACTION.EDIT_LIMITS);
        }
        if (path === '/settings/multileg-security-master') {
          return showMultiLegSecmaster;
        }
        if (path === '/settings/credentials') {
          return isAuthorized(ACTION.VIEW_CREDENTIALS);
        }
        if (path === '/settings/trading-controls') {
          return enableTradingControls && isAuthorized(ACTION.VIEW_TRADING_CONTROLS);
        }
        if (path === '/settings/address-defaults') {
          return isPMSActive && isAuthorized(ACTION.EDIT_ADDRESSES);
        }
        return true;
      }),
    [isAuthorized, showMultiLegSecmaster, enableTradingControls, isPMSActive]
  );
}
