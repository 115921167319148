import { useCallback, useState } from 'react';

import {
  AccordionGroup,
  BLOTTER_TABLE_FILTERS_CONTAINER_ID,
  Box,
  MixpanelEvent,
  Tab,
  TabList,
  TabPanels,
  TabSize,
  Tabs,
  formattedDateForSubscription,
  tabLabelerEnumerated,
  useConstant,
  useDynamicCallback,
  useMixpanel,
  usePersistedTabs,
  usePortal,
  useTabs,
  type Column,
  type ColumnState,
  type TabProps,
  type Trade,
} from '@talos/kyoko';
import { FilteredTrades } from './FilteredTrades';
import { DEFAULT_TRADE_COLUMNS } from './columns';
import { TRADES_BLOTTER_PREFIX } from './tokens';
import type { BlotterTableTradeFilter } from './useTradeFilter';

export interface TradeTabProps extends TabProps {
  defaultFilter: BlotterTableTradeFilter;
  defaultColumns: (keyof Trade | Partial<Column>)[];
}

const PRESET_TABS: TradeTabProps[] = [
  {
    label: 'Recent',
    id: 'recent-trades',
    closable: false,
    editable: false,
    defaultFilter: {
      StartDate: formattedDateForSubscription(Sugar.Date.create('2 days ago')),
    },
    defaultColumns: DEFAULT_TRADE_COLUMNS,
  },
];

const NEW_TAB_DEFAULTS: TradeTabProps = {
  defaultFilter: {
    StartDate: formattedDateForSubscription(Sugar.Date.create('2 days ago')),
  },
  defaultColumns: DEFAULT_TRADE_COLUMNS,
};

const tabLabeler = tabLabelerEnumerated('Trades');

export function Trades() {
  const mixpanel = useMixpanel();
  const [initialFiltersOpen, setInitialFiltersOpen] = useState(false);

  const handleSelect = useDynamicCallback(() => setInitialFiltersOpen(false));
  const handleAdd = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.AddTab);
    setInitialFiltersOpen(true);
  });

  const defaultInitialItems = useConstant<TradeTabProps[]>(PRESET_TABS);
  const [excludedIDs] = useState(PRESET_TABS.map(tab => tab.id || ''));

  const persistedTabs = usePersistedTabs<TradeTabProps>(TRADES_BLOTTER_PREFIX, {
    defaultInitialItems,
    defaultInitialSelectedIndex: 0,
    excludedIDs,
    onSelect: handleSelect,
  });

  const tabs = useTabs<TradeTabProps>({
    ...persistedTabs,
    showAddTab: true,
    tabLabeler,
    onAdd: handleAdd,
  });

  const handleCloneTab = useCallback(
    (filter: BlotterTableTradeFilter, columns: ColumnState[]) => {
      tabs.clone(tabs.selectedIndex, {
        defaultColumns: columns,
        defaultFilter: filter,
      });
    },
    [tabs]
  );

  const { setPortalRef: filtersContainerRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);

  return (
    <Tabs {...tabs} style={{ height: '100%' }} size={TabSize.Large}>
      <TabList isBordered rightItems={<Box ref={filtersContainerRef} />}>
        {tabs.items.map((tab, idx) => (
          <Tab key={idx} {...tab} reorderable={true} />
        ))}
      </TabList>
      <TabPanels style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
        {tabs.items.map((tab, idx) => (
          <AccordionGroup key={tab.id}>
            <FilteredTrades
              key={idx}
              blotterPersistID={`${TRADES_BLOTTER_PREFIX}/${tab.id}`}
              tabLabel={tab.label}
              defaultColumns={'defaultColumns' in tab ? tab.defaultColumns : NEW_TAB_DEFAULTS.defaultColumns}
              defaultFilter={'defaultFilter' in tab ? tab.defaultFilter : NEW_TAB_DEFAULTS.defaultFilter}
              onCloneTab={handleCloneTab}
              initialIsOpen={initialFiltersOpen}
              showCreateManualTrade={true}
              showAPIOrdersToggle={true}
            />
          </AccordionGroup>
        ))}
      </TabPanels>
    </Tabs>
  );
}
