import {
  BlotterDensity,
  BlotterTable,
  BlotterTableFilters,
  ExportDialog,
  MaxRecordsReachedWarning,
  MixpanelEvent,
  MixpanelEventProperty,
  MixpanelEventSource,
  SummaryLine,
  createCSVFileName,
  filterByColumnMainMenuItems,
  getOrderAmount,
  getOrderQuantity,
  orderStatusFilterTextToBackendStatus,
  removeEmptyFilters,
  useAccordionFilterBuilder,
  useBlotterTable,
  useDateRangeFilter,
  useDisclosure,
  useMixpanel,
  usePersistedBlotterTable,
  type BlotterTableFilter,
  type BlotterTableRow,
  type Column,
  type CompositePipeFunction,
  type FilterClause,
  type Order,
  type TimestampField,
  type UnifiedLiquidityEnum,
} from '@talos/kyoko';
import type { GetMainMenuItemsParams } from 'ag-grid-enterprise';
import * as routes from 'containers/Routes/routes';
import { getOrderDetailsRoute } from 'containers/Routes/routes';
import type { GenerateOrderDetailsRoute } from 'containers/Trading/Markets/OrderDetails/types';
import { isEqual, pick } from 'lodash';
import { useDisplaySettings } from 'providers/AppConfigProvider';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { map, pipe } from 'rxjs';
import { combineLatestWith } from 'rxjs/operators';
import { useFeatureFlag } from '../../../hooks';
import { useOrderColumns } from './Columns';
import { OrderOptions } from './OrderOptions';
import { useFilteredOrdersBlotterObs } from './useFilteredOrdersBlotterObs';
import { useHandleExportOrders } from './useHandleExportOrders';
import {
  ORDER_SEARCH_KEYS,
  colIDToFilterBuilderKey,
  useOrderFilter,
  type BlotterTableOrderFilter,
} from './useOrderFilter';
import { useOrderMenu } from './useOrderMenu';
import { useRecentCustomerOrders } from './useRecentCustomerOrders';

interface FilteredOrdersParams {
  blotterID: string;
  tabLabel?: string;
  defaultFilter: BlotterTableFilter;
  defaultColumns?: (keyof Order | Partial<Column>)[];

  /** filter and columns are current state to be cloned to new tab */
  onCloneTab: (filter: BlotterTableFilter, columns: Column[]) => void;

  /** When mounting should the filter accordian be expanded */
  initialIsOpen?: boolean;
  onDoubleClickRow?: (data: Order) => void;
  generateOrderDetailsRoute?: GenerateOrderDetailsRoute;
}

const timestampFields: TimestampField[] = [
  {
    Name: '',
    DisplayName: 'Default',
    Description: 'Returns any orders submitted or updated in the specified period.',
  },
  {
    Name: 'SubmitTime',
    DisplayName: 'Submit Date',
  },
  {
    Name: 'Timestamp',
    DisplayName: 'Updated At',
  },
  {
    Name: 'LastTradeTime',
    DisplayName: 'Last Trade Time',
  },
];

export const FilteredOrders = function FilteredOrders({
  blotterID,
  tabLabel,
  defaultFilter,
  defaultColumns,
  onCloneTab,
  initialIsOpen,
  onDoubleClickRow: handleDoubleClickRow,
  generateOrderDetailsRoute = routes.getOrderDetailsRoute,
}: FilteredOrdersParams) {
  const mixpanel = useMixpanel();
  const history = useHistory();
  const { homeCurrency } = useDisplaySettings();
  const [selectedOrders, setSelectedOrders] = useState<Order[]>([]);

  const columns = useOrderColumns({ defaultColumns });

  const persisted = usePersistedBlotterTable<Order>(blotterID, {
    columns,
    filter: defaultFilter,
    sort: '-SubmitTime',
  });

  const filteredOrders = useOrderFilter({
    initialFilter: persisted.initialFilter,
    saveFilter: persisted.onFilterChanged,
    includeStatusFilter: true,
    includeIDFilter: true,
  });
  const { clientSideFilter, changeFilter, filterableProperties, initialFilterClauses } = filteredOrders;

  const handleRowSelectionChanged = useCallback((selectedRows: BlotterTableRow<Order>[]) => {
    setSelectedOrders(selectedRows.map(row => row.data));
  }, []);

  // Forward the parts of the filter being sent to the backend
  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>) => {
      changeFilter(curr => {
        const newFilter = removeEmptyFilters({
          ...curr,
          Markets: filterClausesByPropertyKey.get('Markets')?.selections,
          SelectedMarkets: filterClausesByPropertyKey.get('SelectedMarkets')?.selections,
          TradedMarkets: filterClausesByPropertyKey.get('TradedMarkets')?.selections,
          Sides: filterClausesByPropertyKey.get('Sides')?.selections,
          Symbols: filterClausesByPropertyKey.get('Symbols')?.selections,
          // Save the UI bound statuses in _ key. Save the real OrdStatuses used as Statuses
          _statuses: filterClausesByPropertyKey.get('_statuses')?.selections,
          Statuses: orderStatusFilterTextToBackendStatus(filterClausesByPropertyKey.get('_statuses')?.selections),
          Strategies: filterClausesByPropertyKey.get('Strategies')?.selections,
          SubAccounts: filterClausesByPropertyKey.get('SubAccounts')?.selections,
          Users: filterClausesByPropertyKey.get('Users')?.selections,
          Warnings: filterClausesByPropertyKey.get('Warnings')?.selections,
          ProductTypes: filterClausesByPropertyKey.get('ProductTypes')?.selections,
          UnifiedLiquidity: filterClausesByPropertyKey.get('UnifiedLiquidity')?.selections as
            | UnifiedLiquidityEnum[]
            | undefined,
          // below are exact search and only one value is supported by backend
          OrderID: filterClausesByPropertyKey.get('OrderID')?.selections?.[0],
          RFQID: filterClausesByPropertyKey.get('RFQID')?.selections?.[0],
          ClOrdID: filterClausesByPropertyKey.get('ClOrdID')?.selections?.[0],
          ParentOrderID: filterClausesByPropertyKey.get('ParentOrderID')?.selections?.[0],
          ParentRFQID: filterClausesByPropertyKey.get('ParentRFQID')?.selections?.[0],
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: initialIsOpen },
    filterBuilderProps: {
      initialFilterClauses,
      properties: filterableProperties,
      onFilterClausesChanged: handleFilterClausesChanged,
    },
  });

  const { openClause } = filterBuilderAccordion;

  const getExtraMainMenuItems = useCallback(
    (params: GetMainMenuItemsParams) => {
      return filterByColumnMainMenuItems({ params, colIDToFilterBuilderKey, openClause, mixpanel });
    },
    [mixpanel, openClause]
  );

  const { getContextMenuItems, dialogComponents } = useOrderMenu({
    openClause,
    filterableProperties,
    generateOrderDetailsRoute,
  });

  const customerOrderByOrderIDObs = useRecentCustomerOrders(filteredOrders.filter);
  const enrichWithCustomerOrderFieldsPipe = useMemo(
    () =>
      pipe(
        combineLatestWith(customerOrderByOrderIDObs),
        map(([orders, customerOrders]) => {
          const enrichedData = orders.data.map(order => {
            if (order.ParentOrderID && customerOrders.has(order.ParentOrderID)) {
              const customerOrder = customerOrders.get(order.ParentOrderID);
              order.enrichOrderWithCustomerOrder(customerOrder);
            }
            return order;
          });
          return {
            ...orders,
            data: enrichedData,
          };
        })
      ),
    [customerOrderByOrderIDObs]
  ) satisfies CompositePipeFunction<Order>;

  const { showBlotterPauseButton } = useFeatureFlag();

  const serverFilter = useMemo(() => onlyServerFilterKeys(filteredOrders.filter), [filteredOrders.filter]);
  const { dataObservable, limitReachedState, raisePaginationLimits } = useFilteredOrdersBlotterObs({
    serverFilter,
  });

  // This call owns the true filter state
  const blotterTable = useBlotterTable({
    dataObservable,
    sort: '-SubmitTime',
    rowID: 'OrderID',
    density: BlotterDensity.Compact,
    rowSelection: 'multiple',
    clientLocalFilter: clientSideFilter,
    getContextMenuItems,
    onRowSelectionChanged: handleRowSelectionChanged,
    columns: persisted.columns,
    onColumnsChanged: persisted.onColumnsChanged,
    onSortChanged: persisted.onSortChanged,
    getExtraMainMenuItems,
    pipe: enrichWithCustomerOrderFieldsPipe,
    onDoubleClickRow(data) {
      mixpanel.track(MixpanelEvent.OpenOrderDetails, {
        [MixpanelEventProperty.Source]: MixpanelEventSource.OrdersBlotter,
      });
      if (handleDoubleClickRow) {
        handleDoubleClickRow(data);
      } else {
        history.push(getOrderDetailsRoute({ orderID: data.OrderID, tab: 'details', type: 'principal' }));
      }
    },
    quickSearchParams: {
      entitySearchKeys: ORDER_SEARCH_KEYS,
    },
    pauseParams: {
      showPauseButton: showBlotterPauseButton,
    },
  });

  const dateRangeFilter = useDateRangeFilter(filteredOrders.filter, changeFilter);

  const handleExport = useCallback(() => {
    blotterTable.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'Orders',
        tabLabel,
      }),
    });
  }, [blotterTable, tabLabel]);

  const handleCloneTab = useCallback(() => {
    onCloneTab(filteredOrders.filter, blotterTable.getColumns());
  }, [blotterTable, filteredOrders.filter, onCloneTab]);

  const exportDialog = useDisclosure();
  const handleExportServerData = useHandleExportOrders({ filter: filteredOrders.filter });

  return (
    <>
      <BlotterTableFilters
        {...filterBuilderAccordion}
        {...dateRangeFilter}
        {...blotterTable.blotterTableFiltersProps}
        timestampFields={timestampFields}
        suffix={
          <OrderOptions
            changeFilter={changeFilter}
            filter={filteredOrders.filter}
            handleExport={() => {
              mixpanel.track(MixpanelEvent.ExportRowsModalOpen);
              exportDialog.open();
            }}
            handleCloneTab={handleCloneTab}
            tabType="FilteredOrders"
          />
        }
      />
      <MaxRecordsReachedWarning
        showLimitReached={!!limitReachedState?.lastRecord}
        earliestTimestamp={limitReachedState?.lastRecord?.Timestamp}
        recordsReceived={limitReachedState?.recordsReceived}
        onRaiseLimitClicked={raisePaginationLimits}
      />
      <BlotterTable {...blotterTable} />
      <SummaryLine
        homeCurrency={homeCurrency}
        rows={selectedOrders}
        getQuantity={getOrderQuantity}
        getAmount={getOrderAmount}
      />
      {dialogComponents}
      <ExportDialog
        {...exportDialog}
        handleExportLocalData={handleExport}
        handleExportServerData={handleExportServerData}
      />
    </>
  );
};

function onlyServerFilterKeys(filter: BlotterTableOrderFilter | undefined) {
  if (!filter) {
    return filter;
  }
  const serverFilter = pick(filter, [
    'TimestampField',
    'StartDate',
    'EndDate',
    'Statuses',
    'SubAccounts',
    'Symbols',
    'HideApiCalls',
    'OrderID',
    'RFQID',
    'ClOrdID',
    'ParentOrderID',
    'ParentRFQID',
  ]);
  if (filter._statuses) {
    serverFilter.Statuses = orderStatusFilterTextToBackendStatus(filter._statuses);
  }
  return serverFilter;
}
